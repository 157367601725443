import {
  ApiResponse,
  DetailBaseService,
  Dict,
  findItemInList,
  InlineStorage,
  removeChild,
  RouterLink,
} from '@amirsavand/ngx-common';
import { EventEmitter, Inject, Injectable, Injector } from '@angular/core';
import { Params } from '@angular/router';
import { AppService } from '@app/app.service';
import { StorageGroup, StorageGroupItem } from '@app/shared/classes';
import { PusherEvent } from '@app/shared/enums/pusher-event';
import { ProfileMember } from '@app/shared/interfaces/profile-member';
import { ProfileTenant } from '@app/shared/interfaces/profile-tenant';
import { ApiService } from '@app/shared/services/api.service';
import { AuthService } from '@app/shared/services/auth.service';
import { PusherService } from '@app/shared/services/pusher.service';
import {
  Member,
  MemberPublic,
  MessageApi,
  MessagePusherApi,
  MessageReactionPusherApi,
  PusherEventOperation,
  Room,
  RoomApi,
  RoomMember,
  RoomMemberPusherApi,
  RoomMemberPusherData,
  RoomMemberTypingPusherApi,
  RoomMemberTypingPusherData,
  RoomPusherApi,
  RoomPusherData,
} from '@app/tenant';
import { API_SERVICE, AUTH_SERVICE } from '@SavandBros/savandbros-ngx-common';
import { captureMessage } from '@sentry/angular-ivy';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TenantService extends DetailBaseService<ProfileTenant> {
  /** Members in-storage cached inline storage instance. */
  private readonly membersCache = new InlineStorage<Dict<MemberPublic[]>>('members', {});

  /** Rooms in-storage cached inline storage instance. */
  private readonly roomsCache = new InlineStorage<Dict<RoomApi[]>>('rooms', {});

  /** Observables to unsubscribe later. */
  private subscriptions = new Subscription();

  /** Trigger to open the sidebar for mobile. */
  public readonly showSidebar = new EventEmitter<void>();

  /** Subject of authenticated member. */
  public readonly authenticatedMemberSubject = new BehaviorSubject<Member | null>(null);

  /** ProfileMember data of authenticated user in this tenant. */
  public readonly member = new BehaviorSubject<ProfileMember | null>(null);

  /** All members of this tenant. */
  public readonly members = new BehaviorSubject<Member[]>([]);

  /** Current room that user is viewing (PK). */
  public readonly room = new BehaviorSubject<Room['id'] | null>(null);

  /** All rooms that authenticated user member is a part of. */
  public readonly rooms = new BehaviorSubject<Room[] | null>(null);

  /** Triggers when a room event comes from pusher. */
  public readonly roomPusher = new EventEmitter<RoomPusherData>();

  /** Triggers when a room member event comes from pusher. */
  public readonly roomMemberPusher = new EventEmitter<RoomMemberPusherData>();

  /** Triggers when a room member typing event comes from pusher. */
  public readonly roomMemberTypingPusher = new EventEmitter<RoomMemberTypingPusherData>();

  /**
   * Flag to know if tenant data is ready.
   *
   * This flag will be set to false when tenant component
   * is in progress of loading the tenant data.
   *
   * While this is false, {@link MessageListComponent.load}
   * will not load and will be triggered again after the
   * loading of tenant data is complete (due to if
   * condition on the router outlet of the tenant
   * component).
   */
  public ready = false;

  /** @returns authenticated user member from {@link members}. */
  public get authenticatedMember(): Member {
    const profileMember: ProfileMember | null = this.member.value;
    if (!profileMember) {
      throw new Error('[TenantService] authenticatedMember() was called when profile member is not set.');
    }
    return this.getMemberById(profileMember.id);
  }

  /** @returns starred messages storage group instance. */
  public get starredMessages(): StorageGroup<StorageGroupItem> {
    return StorageGroup.getOrCreate({
      database: 'inbox',
      table: 'starred',
      limitation: 500,
    });
  }

  constructor(
    @Inject(API_SERVICE) private readonly apiService: ApiService,
    @Inject(AUTH_SERVICE) private readonly authService: AuthService,
    private readonly appService: AppService,
    private readonly pusherService: PusherService,
    private readonly injector: Injector,
  ) {
    super();
  }

  /**
   * Handler (callback) for all room events from pusher.
   *
   * @param data Event data.
   * @param operation Event operation.
   */
  private roomEventHandler(data: RoomPusherApi, operation: PusherEventOperation): void {
    /** Set up the emit data (room only). */
    let room: Room | null = null;
    /** Logic based on operation. */
    switch (operation) {
      case PusherEventOperation.CREATE: {
        /** Create the room instance. */
        room = new Room({ ...data, room_members: [] }, this.injector);
        /** Add the new room to the list. */
        const rooms: Room[] | null = this.rooms.getValue();
        if (rooms) {
          this.rooms.next([room, ...rooms]);
        }
        break;
      }
      case PusherEventOperation.UPDATE: {
        /** Find the room instance locally. */
        room = this.getRoomById(data.id);
        /** Trigger update on the room as well. */
        room.onChange({ room, data, operation });
        break;
      }
      case PusherEventOperation.DESTROY: {
        /** Find the room instance locally. */
        room = this.getRoomById(data.id);
        /** Remove the room from the local list. */
        const rooms: Room[] | null = this.rooms.getValue();
        if (rooms) {
          this.rooms.next(rooms.filter((item: Room): boolean => item.id !== data.id));
        }
        break;
      }
    }
    /** Emit to room member. */
    if (room) {
      this.roomPusher.emit({ room, data, operation });
    }
  }

  /**
   * Handler (callback) for all room member events
   * from pusher.
   *
   * @param data Event data.
   * @param operation Event operation.
   */
  private roomMemberEventHandler(data: RoomMemberPusherApi, operation: PusherEventOperation): void {
    /**
     * In case of room member creation for a private room for the authenticated
     * member, we must load the room via API and add it to the list first.
     */
    if (operation === PusherEventOperation.CREATE && !this.existsRoomById(data.room)) {
      /**
       * Let's load the room from API then add it to the list
       * then trigger the same method again but this time the
       * room will be available.
       */
      this.apiService.room.retrieve(data.room).subscribe({
        next: (roomData: RoomApi): void => {
          this.addRoom(roomData);
          this.roomMemberEventHandler(data, operation);
        },
        error: (): void => {
          captureMessage('[TenantService] roomMemberEventHandler failed to retrieve non-existing room.');
        },
      });
      return;
    }
    /** Set up the emit data. */
    const emitData: RoomMemberPusherData = { data, operation };
    /** RoomMember class only available for non-destroy operations. */
    if (operation !== PusherEventOperation.DESTROY) {
      emitData.roomMember = new RoomMember(data, this.injector);
    }
    /** Update the room with the new members. */
    this.getRoomById(data.room).onRoomMembersChange(emitData);
    /** Emit to room member. */
    this.roomMemberPusher.emit(emitData);
  }

  /**
   * Handler (callback) for all message events.
   * Updates cached messages based on the event operation.
   *
   * @param data Event data containing message details.
   * @param operation Specifies the type of operation: CREATE, UPDATE, or DESTROY.
   */
  private messageEventHandler(data: MessagePusherApi, operation: PusherEventOperation): void {
    // Retrieve the storage instance for the specified room
    const storage: StorageGroup<MessageApi> | null = StorageGroup.get({
      database: 'room-message',
      table: String(data.room),
    });
    // Exit if the storage instance does not exist
    if (!storage) {
      return;
    }
    // Handle the DESTROY operation (removing the message)
    if (operation === PusherEventOperation.DESTROY) {
      // Remove the message from storage using its ID
      storage.destroy(data.id);
    }
    // Handle CREATE or UPDATE operations (saving the message)
    else if (operation === PusherEventOperation.CREATE || operation === PusherEventOperation.UPDATE) {
      // Save or update the message in storage
      storage.set(data);
    }
  }

  /**
   * Handler (callback) for all message reaction events.
   * Updates cached messages in response to reaction changes.
   *
   * @param data Event data containing message and reaction details.
   * @param operation Specifies whether the event is a CREATE or DESTROY operation.
   */
  private messageReactionEventHandler(data: MessageReactionPusherApi, operation: PusherEventOperation): void {
    // Retrieve the storage instance for the given room
    const storage: StorageGroup<MessageApi> | null = StorageGroup.get({
      database: 'room-message',
      table: String(data.room),
    });
    // Exit if the storage instance does not exist
    if (!storage) {
      return;
    }
    // Retrieve the cached message from storage by its ID
    storage.retrieve(data.id).then((message: MessageApi | null): void => {
      // Exit if the message does not exist
      if (!message) {
        return;
      }
      // Handle the DESTROY operation (reaction removal)
      if (operation === PusherEventOperation.DESTROY) {
        // Exit if the message has no reactions
        if (!message.reactions) {
          return;
        }
        // Check if the specific reaction exists in the message
        if (data.reaction.reaction in message.reactions) {
          // Remove the user from the list of members for this reaction
          removeChild(message.reactions[data.reaction.reaction], data.reaction.member);
          // If no users remain for this reaction, delete it from the dictionary
          if (!message.reactions[data.reaction.reaction].length) {
            delete message.reactions[data.reaction.reaction];
          }
        }
      }
      // Handle the CREATE operation (adding a reaction)
      else if (operation === PusherEventOperation.CREATE) {
        // Initialize the reactions dictionary if it doesn't exist
        if (!message.reactions) {
          message.reactions = {};
        }
        // Check if the reaction already exists in the dictionary
        if (data.reaction.reaction in message.reactions) {
          // Add the user to the list if they haven't reacted with this yet
          if (!message.reactions[data.reaction.reaction].includes(data.reaction.member)) {
            message.reactions[data.reaction.reaction].push(data.reaction.member);
          }
        } else {
          // Create a new entry for this reaction with the user as the first member
          message.reactions[data.reaction.reaction] = [data.reaction.member];
        }
      }
      // Save the updated message back to storage
      storage.set(message);
    });
  }

  /** Activate subscriptions of this service. */
  public activate(): void {
    /** Watch event to create member. */
    this.subscriptions.add(
      this.pusherService.events[PusherEvent.MEMBER_CREATE].subscribe({
        next: (data: MemberPublic): void => {
          this.members.next([...this.members.value, new Member(data, this.injector)]);
        },
      }),
    );
    /** Watch event to update member. */
    this.subscriptions.add(
      this.pusherService.events[PusherEvent.MEMBER_UPDATE].subscribe({
        next: (data: MemberPublic): void => {
          const member: Member | undefined = findItemInList(this.members.value, data.id);
          if (member) {
            member.onChange(data);
          } else {
            const message: string = `[TenantService] ${PusherEvent.MEMBER_UPDATE} triggered for non-existing member.`;
            console.warn(message);
            captureMessage(message);
          }
        },
      }),
    );
    /** Watch event to emit room. */
    this.subscriptions.add(
      this.pusherService.events[PusherEvent.ROOM_CREATE].subscribe({
        next: (data: RoomPusherApi): void => {
          this.roomEventHandler(data, PusherEventOperation.CREATE);
        },
      }),
    );
    /** Watch event to emit room. */
    this.subscriptions.add(
      this.pusherService.events[PusherEvent.ROOM_UPDATE].subscribe({
        next: (data: RoomPusherApi): void => {
          this.roomEventHandler(data, PusherEventOperation.UPDATE);
        },
      }),
    );
    /** Watch event to emit room. */
    this.subscriptions.add(
      this.pusherService.events[PusherEvent.ROOM_DESTROY].subscribe({
        next: (data: RoomPusherApi): void => {
          this.roomEventHandler(data, PusherEventOperation.DESTROY);
        },
      }),
    );
    /** Watch event to emit room member. */
    this.subscriptions.add(
      this.pusherService.events[PusherEvent.ROOM_MEMBER_CREATE].subscribe({
        next: (data: RoomMemberPusherApi): void => {
          this.roomMemberEventHandler(data, PusherEventOperation.CREATE);
        },
      }),
    );
    /** Watch event to emit room member. */
    this.subscriptions.add(
      this.pusherService.events[PusherEvent.ROOM_MEMBER_UPDATE].subscribe({
        next: (data: RoomMemberPusherApi): void => {
          this.roomMemberEventHandler(data, PusherEventOperation.UPDATE);
        },
      }),
    );
    /** Watch event to emit room member. */
    this.subscriptions.add(
      this.pusherService.events[PusherEvent.ROOM_MEMBER_DESTROY].subscribe({
        next: (data: RoomMemberPusherApi): void => {
          this.roomMemberEventHandler(data, PusherEventOperation.DESTROY);
        },
      }),
    );
    /** Watch event to emit room member typing. */
    this.subscriptions.add(
      this.pusherService.events[PusherEvent.ROOM_MEMBER_TYPING].subscribe({
        next: (data: RoomMemberTypingPusherApi): void => {
          if (!this.rooms.value || !this.members.value.length) {
            return;
          }
          const emitData: RoomMemberTypingPusherData = {
            operation: PusherEventOperation.NONE,
            room: this.getRoomById(data.id),
            member: this.getMemberById(data.member),
            message: data.message,
            data,
          };
          emitData.room.onRoomMemberTyping(emitData);
          this.roomMemberTypingPusher.emit(emitData);
        },
      }),
    );
    /** Watch event of message creation. */
    this.subscriptions.add(
      this.pusherService.events[PusherEvent.MESSAGE_CREATE].subscribe({
        next: (data: MessagePusherApi): void => {
          this.messageEventHandler(data, PusherEventOperation.CREATE);
        },
      }),
    );
    /** Watch event of message update. */
    this.subscriptions.add(
      this.pusherService.events[PusherEvent.MESSAGE_UPDATE].subscribe({
        next: (data: MessagePusherApi): void => {
          this.messageEventHandler(data, PusherEventOperation.UPDATE);
        },
      }),
    );
    /** Watch event of message deletion. */
    this.subscriptions.add(
      this.pusherService.events[PusherEvent.MESSAGE_DESTROY].subscribe({
        next: (data: MessagePusherApi): void => {
          this.messageEventHandler(data, PusherEventOperation.DESTROY);
        },
      }),
    );
    /** Watch event of message reaction creation. */
    this.subscriptions.add(
      this.pusherService.events[PusherEvent.MESSAGE_REACTION_CREATE].subscribe({
        next: (data: MessageReactionPusherApi): void => {
          this.messageReactionEventHandler(data, PusherEventOperation.CREATE);
        },
      }),
    );
    /** Watch event of message reaction deletion. */
    this.subscriptions.add(
      this.pusherService.events[PusherEvent.MESSAGE_REACTION_DESTROY].subscribe({
        next: (data: MessageReactionPusherApi): void => {
          this.messageReactionEventHandler(data, PusherEventOperation.DESTROY);
        },
      }),
    );
  }

  /** Load public members from API or use cache then update it. */
  public membersLoad(callback: (data: MemberPublic[]) => void): void {
    if (!this.pk.value) {
      throw new Error('[TenantService] membersLoad() called when PK is not set.');
    }
    const key: string = String(this.pk.value);
    const cache: Dict<MemberPublic[]> = this.membersCache.value;
    if (key in cache && cache[key].length) {
      callback(cache[key]);
    }
    this.subscriptions.add(
      this.apiService.memberPublic.list().subscribe({
        next: (data: MemberPublic[]): void => {
          this.membersCache.value = { ...cache, [key]: data };
          callback(data);
        },
      }),
    );
  }

  /** Load rooms from API or use cache then update it. */
  public roomsLoad(member: Member['id'], callback: (data: RoomApi[]) => void): void {
    if (!this.pk.value) {
      throw new Error('[TenantService] roomsLoad() called when PK is not set.');
    }
    const key: string = String(this.pk.value);
    const cache: Dict<RoomApi[]> = this.roomsCache.value;
    if (key in cache && cache[key].length) {
      callback(cache[key]);
    }
    this.subscriptions.add(
      this.apiService.room.list({ limit: String(100) }, undefined, member).subscribe({
        next: (data: ApiResponse<RoomApi>): void => {
          this.roomsCache.value = { ...cache, [key]: data.results };
          callback(data.results);
        },
      }),
    );
  }

  /** Deactivate subscriptions of this service. */
  public deactivate(): void {
    this.subscriptions.unsubscribe();
    this.subscriptions = new Subscription();
  }

  /** @see DetailBaseService.clear */
  public override clear(): void {
    this.setOffline();
    super.clear();
    this.appService.tenantPendingRequests.next();
    this.deactivate();
    this.authenticatedMemberSubject.next(null);
    this.member.next(null);
    this.members.next([]);
    this.rooms.next(null);
  }

  /**
   * Attempts to find the member with the given ID.
   * Using it when members are not set will cause an error.
   * @param id ID of the member to find.
   * @returns member instance.
   */
  public getMemberById(id: Member['id']): Member {
    const member: Member | undefined = findItemInList(this.members.value, id);
    if (!member) {
      throw new Error(`[TenantService] getMemberById failed to find member with given ID ${id}.`);
    }
    return member;
  }

  /**
   * Attempts to find the room with the given ID.
   * Using it when rooms are not set will cause an error.
   * @param id ID of the room to find.
   * @returns room instance.
   */
  public getRoomById(id: Room['id']): Room {
    if (!this.rooms.value) {
      throw new Error('[TenantService] getRoomById called when rooms are not set.');
    }
    const room: Room | undefined = findItemInList(this.rooms.getValue() as Room[], id);
    if (!room) {
      throw new Error(`[TenantService] getRoomById failed to find room with given ID ${id}.`);
    }
    return room;
  }

  /**
   * Checks for room existence by given ID.
   * @param id ID of the room to check.
   * @returns true if exists.
   */
  public existsRoomById(id: Room['id']): boolean {
    const rooms: Room[] | null = this.rooms.getValue();
    if (!rooms) {
      return false;
    }
    return rooms.some((room: Room): boolean => room.id === id);
  }

  /** @returns router path for organization plus given pages. */
  public getPath(pages: RouterLink['path'] = [], params: Params = {}): RouterLink {
    return { path: ['/', this.pk.value, ...pages], params };
  }

  /**
   * Add a new room to existing rooms.
   * @param data Room API data.
   */
  public addRoom(data: RoomApi): void {
    const rooms: Room[] = (this.rooms.getValue() as Room[]).filter((room: Room): boolean => room.id !== data.id);
    this.rooms.next([...rooms, new Room(data, this.injector)]);
  }

  /**
   * Update the local profile tenant data.
   * @param data Updated values.
   */
  public updateData(data: Partial<ProfileTenant>): void {
    const local: ProfileTenant | null = this.data.value;
    if (local) {
      this.data.next(Object.assign(local, data));
    }
  }

  /** Set the current member in this tenant to offline. */
  public setOffline(): void {
    if (!this.pk.value || !this.member.value || !this.authService.access.value) {
      return;
    }
    this.apiService.setMemberOffline(this.authService.access.value, this.pk.value, this.member.value.id);
  }
}
