import { removeChild } from '@amirsavand/ngx-common';
import { Member, RoomMemberTypingPusherData } from '@app/tenant';
import { BehaviorSubject } from 'rxjs';

/**
 * The `IsTypingReceiver` class manages the typing
 * state of members in a room, such as a chat or collaborative
 * environment.
 *
 * This class tracks which members are currently typing,
 * provides a way to receive typing events, and removes
 * a member's typing status after a specified timeout
 * period (`lifetime`).
 */
export class IsTypingReceiver {
  /** Typing status subject.  */
  public readonly status = new BehaviorSubject<boolean>(false);

  /** List of members who are typing. */
  public readonly members: Member[] = [];

  /** Dictionary of last time a member was typing. */
  private readonly memberTime: Record<Member['id'], number> = {};

  /** @param lifetime Time to stop a member's typing status.  */
  constructor(private readonly lifetime: number = 5000) {}

  /**
   * Handles a typing event for a room member.
   *
   * Adds a member to the list of typing members and
   * starts a timer to remove them after `typingTimeout`
   * if they stop typing.
   *
   * @param data Pusher event data containing the
   * typing member.
   */
  public onIsTyping(data: RoomMemberTypingPusherData): void {
    this.status.next(true);
    if (!this.members.includes(data.member)) {
      this.members.push(data.member);
    }
    this.memberTime[data.member.id] = Date.now();
    setTimeout((): void => {
      if (!this.members.includes(data.member)) {
        return;
      }
      if (Date.now() - this.memberTime[data.member.id] < this.lifetime - 250) {
        return;
      }
      removeChild(this.members, data.member);
      this.status.next(this.members.length !== 0);
    }, this.lifetime);
  }
}
