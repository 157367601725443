export enum PusherEvent {
  /** Member */
  MEMBER_CREATE = 'tenant_member_created',
  MEMBER_UPDATE = 'tenant_member_updated',
  /** Message */
  MESSAGE_CREATE = 'message_created',
  MESSAGE_UPDATE = 'message_updated',
  MESSAGE_DESTROY = 'message_deleted',
  /** Room */
  ROOM_CREATE = 'room_created',
  ROOM_UPDATE = 'room_updated',
  ROOM_DESTROY = 'room_deleted',
  /** Room Member */
  ROOM_MEMBER_CREATE = 'room_member_created',
  ROOM_MEMBER_UPDATE = 'room_member_updated',
  ROOM_MEMBER_DESTROY = 'room_member_deleted',
  /** Room Member typing */
  ROOM_MEMBER_TYPING = 'room_member_typing',
  /** Message Attachment */
  MESSAGE_ATTACHMENT_CREATE = 'message_attachment_created',
  MESSAGE_ATTACHMENT_DESTROY = 'message_attachment_deleted',
  /** Message Reaction */
  MESSAGE_REACTION_CREATE = 'message_reaction_created',
  MESSAGE_REACTION_DESTROY = 'message_reaction_deleted',
  /** Misc */
  ONLINE_MEMBERS = 'online_members',
}
