import { HttpErrorResponse, removeChild } from '@amirsavand/ngx-common';
import { SelectItem } from '@amirsavand/ngx-input';
import { Injector } from '@angular/core';
import { AppService } from '@app/app.service';
import { Link } from '@app/shared/classes/link';
import { UnreadMessage } from '@app/shared/interfaces/unread-message';
import { ApiService } from '@app/shared/services/api.service';
import { UnreadService } from '@app/shared/services/unread.service';
import {
  IsTypingReceiver,
  Member,
  MemberRole,
  PusherEventOperation,
  RoomApi,
  RoomKind,
  RoomMember,
  RoomMemberApi,
  RoomMemberPusherData,
  RoomMemberRole,
  RoomMemberTypingPusherData,
  RoomPusherData,
  TenantService,
} from '@app/tenant';
import { faHashtag } from '@fortawesome/free-solid-svg-icons';
import { API_SERVICE } from '@SavandBros/savandbros-ngx-common';
import { captureMessage } from '@sentry/angular-ivy';
import { BehaviorSubject } from 'rxjs';

export class Room {
  /** Injected service. */
  private readonly tenantService: TenantService = this.injector.get(TenantService);

  /** Injected service. */
  private readonly apiService = this.injector.get(API_SERVICE) as ApiService;

  /** Injected service. */
  private readonly appService: AppService = this.injector.get(AppService);

  /** Injected service. */
  private readonly unreadService: UnreadService = this.injector.get(UnreadService);

  /** Unread status subject. */
  public readonly unread = new BehaviorSubject<boolean>(false);

  /** Is typing receiver instance. */
  public readonly isTyping = new IsTypingReceiver();

  /** Whether this room is protected. */
  public readonly isProtected = this.init.is_protected;

  /** Unique ID of this room. */
  public id: number = this.init.id;

  /** Creator of this room. */
  public member: Member = this.tenantService.getMemberById(this.init.member);

  /** Name of this room. */
  public name: string = this.init.name;

  /** UI display name of this room. */
  public displayName: string = this.name;

  /** Description of this room. */
  public description: string = this.init.description;

  /** Kind of this room. */
  public kind: RoomKind = this.init.kind;

  /** Room member instances of this room. */
  public roomMembers: RoomMember[] = this.init.room_members.map(
    (item: RoomMemberApi): RoomMember => new RoomMember({ ...item, room: this.id }, this.injector),
  );

  /** Is authenticated member part of this room? */
  public isMember = false;

  /**
   * Room member instance of this room of authenticated
   * user member.
   */
  public authenticatedRoomMember?: RoomMember;

  /**
   * For direct rooms, other member is the member
   * that is not the authenticated member.
   */
  public otherMember?: Member;

  /** Creation date of this room. */
  public created: Date = new Date(this.init.created);

  /** Last time this room was updated. */
  public updated: Date = new Date(this.init.updated);

  /** UI chatbox input of this room. */
  public chatboxPlaceholder = 'Message';

  /** Can this room be deleted by authenticated member? */
  public canDestroy = false;

  /** Can this room be updated by authenticated member? */
  public canUpdate = false;

  /** API loading indicator for join method. */
  public loadingJoin = false;

  /** API loading indicator for update method. */
  public loadingLeave = false;

  /** API loading indicator for destroy method. */
  public loadingDestroy = false;

  /** @returns UI a link instance repressing this room. */
  public get link(): Link {
    let id: Link['id'];
    let icon: Link['icon'];
    let image: Link['image'];
    let indicator: Link['indicator'];
    if (this.kind === RoomKind.DIRECT) {
      id = this.otherMember?.id;
      indicator = 'secondary';
      if (this.otherMember?.picture) {
        image = this.otherMember.picture.file.url_128x128;
      } else {
        image = '/assets/member.svg';
      }
    } else {
      id = this.id;
      icon = faHashtag;
    }
    return new Link({
      label: this.name,
      routerLink: { path: [this.id] },
      unread: this.unread,
      typing: this.isTyping.status,
      id,
      icon,
      image,
      indicator,
    });
  }

  /** Select item data for mentions. */
  public get selectItem(): SelectItem {
    return {
      name: this.name,
      id: this.id,
    };
  }

  constructor(
    public readonly init: RoomApi,
    private readonly injector: Injector,
  ) {
    this.generateIsMember();
    this.generateAuthenticatedRoomMember();
    this.generateOtherMember();
    this.generateName();
    this.generateDisplayName();
    this.generateChatboxPlaceholder();
    this.generateRoomMembersHasDirect();
    this.generatePermissions();
    this.generateUnread();
  }

  /** Generate value for {@link isMember}. */
  public generateIsMember(): void {
    this.isMember = this.roomMembers.some(
      (roomMember: RoomMember): boolean => roomMember.member.id === this.tenantService.authenticatedMember.id,
    );
  }

  /** Generate value for {@link authenticatedRoomMember}. */
  public generateAuthenticatedRoomMember(): void {
    if (this.isMember) {
      this.authenticatedRoomMember = this.roomMembers.find(
        (roomMember: RoomMember): boolean => roomMember.member.id === this.tenantService.authenticatedMember.id,
      );
    }
  }

  /** Generate value for {@link otherMember}. */
  public generateOtherMember(): void {
    if (this.kind === RoomKind.DIRECT) {
      const other: RoomMember | undefined = this.roomMembers.find(
        (item: RoomMember): boolean => item.member.id !== this.tenantService.member.value?.id,
      );
      if (!other) {
        throw new Error('[Room] generateOtherMember failed to find other member.');
      }
      this.otherMember = other.member;
    }
  }

  /** Generate value for {@link name}. */
  public generateName(): void {
    this.name = this.init.name;
    if (this.otherMember) {
      this.name = this.otherMember.name;
    }
  }

  /** Generate value for {@link displayName}. */
  public generateDisplayName(): void {
    let sign = '#';
    if (this.kind === RoomKind.DIRECT) {
      sign = '@';
    }
    this.displayName = `${sign} ${this.name}`;
  }

  /** Generate value for {@link chatboxPlaceholder}. */
  public generateChatboxPlaceholder(): void {
    this.chatboxPlaceholder = `Message ${this.displayName}`;
  }

  /** Generate value for {@link otherMember} {@link RoomMember.hasDirect}. */
  public generateRoomMembersHasDirect(): void {
    if (this.kind === RoomKind.DIRECT && this.isMember && this.otherMember) {
      this.otherMember.hasDirect = true;
    }
  }

  /**
   * Generate value for permission flags.
   *
   * @see canUpdate
   * @see canDestroy
   *
   * @link https://savandbros.atlassian.net/browse/CHAT-25
   */
  public generatePermissions(): void {
    if (!this.isMember || this.kind === RoomKind.DIRECT) {
      this.canUpdate = false;
      this.canDestroy = false;
      return;
    }
    const authenticatedRoomMemberRole: RoomMemberRole = (this.authenticatedRoomMember as RoomMember).role;
    const authenticatedMember: Member = this.tenantService.authenticatedMember;
    const isAuthenticatedMemberTenantOwner = authenticatedMember.role === MemberRole.OWNER;
    const isAuthenticatedMemberTenantAdmin: boolean = authenticatedMember.role === MemberRole.ADMINISTRATOR;
    const isAuthenticatedRoomMemberAdmin: boolean = authenticatedRoomMemberRole === RoomMemberRole.ADMIN;
    this.canUpdate =
      (this.kind === RoomKind.PRIVATE && authenticatedRoomMemberRole === RoomMemberRole.ADMIN) ||
      (this.kind === RoomKind.PUBLIC && (isAuthenticatedMemberTenantOwner || isAuthenticatedMemberTenantAdmin));
    this.canDestroy =
      (this.kind === RoomKind.PRIVATE && isAuthenticatedRoomMemberAdmin) ||
      (this.kind === RoomKind.PUBLIC && (isAuthenticatedRoomMemberAdmin || isAuthenticatedMemberTenantOwner));
  }

  /** Use unread messages information to update unread status. */
  public generateUnread(): void {
    const unreadMessages: UnreadMessage[] = this.unreadService.unreadMessages.value;
    const isUnread: boolean = unreadMessages.some((message: UnreadMessage): boolean => message.room === this.id);
    this.unread.next(isUnread);
  }

  /**
   * Must be called when data of this room is updated.
   * Regenerate specific data values.
   *
   * @param data Pusher event data.
   */
  public onChange(data: RoomPusherData): void {
    Object.assign(this.init, data.data);
    this.generateName();
    this.generateDisplayName();
    this.generateChatboxPlaceholder();
    this.generatePermissions();
  }

  /**
   * Must be called when a room member of this room
   * is changed.
   *
   * All the room member specific generate methods
   * are invoked.
   *
   * Operation specific actions:
   *
   * - When operation is "destroy", room member instance
   * is removed from the list.
   * - When operation is "create", a new room member
   * instance is added to the list.
   * - When operation is "update", a new room member
   * instance gets replaced in the list.
   *
   * @param data Pusher event data.
   */
  public onRoomMembersChange(data: RoomMemberPusherData): void {
    const index: number = this.roomMembers.findIndex((item: RoomMember): boolean => item.id === data.data.id);
    switch (data.operation) {
      case PusherEventOperation.CREATE: {
        this.roomMembers.unshift(data.roomMember as RoomMember);
        break;
      }
      case PusherEventOperation.UPDATE: {
        if (index !== -1) {
          this.roomMembers.splice(index, 1, data.roomMember as RoomMember);
        }
        break;
      }
      case PusherEventOperation.DESTROY: {
        if (index !== -1) {
          this.roomMembers.splice(index, 1);
        }
        break;
      }
    }
    this.generateIsMember();
    this.generateAuthenticatedRoomMember();
    this.generateOtherMember();
    this.generateName();
    this.generateDisplayName();
    this.generateRoomMembersHasDirect();
    this.generatePermissions();
    /** If room is private and authenticated user is not a member. */
    if (this.kind === RoomKind.PRIVATE && !this.isMember) {
      /** Remove the room from the list. */
      const rooms = this.tenantService.rooms.getValue() as Room[];
      removeChild(rooms, this);
      this.tenantService.rooms.next(rooms);
    }
  }

  /**
   * Must be called when a room member of this room
   * is typing.
   *
   * @param data Pusher event data.
   */
  public onRoomMemberTyping(data: RoomMemberTypingPusherData): void {
    this.isTyping.onIsTyping(data);
  }

  /**
   * Join authenticated member to this room.
   *
   * @param callback Invoked when API call succeeds.
   */
  public join(callback?: () => void): void {
    this.loadingJoin = true;
    if (this.isMember) {
      captureMessage('[Room] join() called on a joined room.');
      this.appService.alertError('Failed to join.');
    } else {
      this.apiService.roomJoin.create(undefined, this.id).subscribe({
        next: (): void => {
          this.loadingJoin = false;
          this.appService.alertSuccess(`Joined ${this.displayName} successfully.`);
          callback?.();
        },
        error: (error: HttpErrorResponse): void => {
          this.loadingJoin = false;
          this.appService.alertErrorApi(error);
        },
      });
    }
  }

  /**
   * Leave authenticated member from this room.
   *
   * @param callback Invoked when API call succeeds.
   */
  public leave(callback?: () => void): void {
    this.loadingLeave = true;
    if (!this.isMember) {
      captureMessage('[Room] leave() called on a non-member room.');
      this.appService.alertError('Failed to leave.');
    } else if (!this.authenticatedRoomMember) {
      captureMessage('[Room] leave() called when user is member but authenticatedRoomMember is not set.');
      this.appService.alertError('Failed to leave.');
    } else {
      this.apiService.roomMember.destroy(this.authenticatedRoomMember.id, this.id).subscribe({
        next: (): void => {
          this.loadingLeave = false;
          this.appService.alertSuccess(`Left ${this.displayName} successfully.`);
          callback?.();
        },
        error: (error: HttpErrorResponse): void => {
          this.loadingLeave = false;
          this.appService.alertErrorApi(error, 'Failed to leave room.');
        },
      });
    }
  }

  /**
   * Delete this room.
   *
   * @param callback Invoked when API call succeeds.
   */
  public destroy(callback?: () => void): void {
    this.loadingDestroy = true;
    this.apiService.room.destroy(this.id).subscribe({
      next: (): void => {
        this.loadingDestroy = false;
        this.appService.alertSuccess(`Deleted ${this.displayName} successfully.`);
        callback?.();
      },
      error: (error: HttpErrorResponse): void => {
        this.loadingDestroy = false;
        this.appService.alertErrorApi(error, 'Failed to delete room.');
      },
    });
  }
}
