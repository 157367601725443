import { ApiResponse, findItemInList, HttpErrorResponse } from '@amirsavand/ngx-common';
import { DOCUMENT } from '@angular/common';
import { EventEmitter, Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Router } from '@angular/router';
import { PusherEvent } from '@app/shared/enums/pusher-event';
import { UnreadMessage } from '@app/shared/interfaces/unread-message';
import { ApiService } from '@app/shared/services/api.service';
import { PusherService } from '@app/shared/services/pusher.service';
import { Member, MessageApi, MessagePusherApi, Room, RoomKind, TenantService } from '@app/tenant';
import { API_SERVICE, stripTags } from '@SavandBros/savandbros-ngx-common';
import { captureMessage } from '@sentry/angular-ivy';
import { BehaviorSubject, Subscription } from 'rxjs';
import { NotificationService } from './notification.service';

/**
 * Handles unread messages and awareness.
 *
 * Here are some of the responsibilities below.
 *
 * - Add unread badge to favicon.
 * - Show desktop notification when room/app is unfocused.
 * - Show unread badge to room in sidebar.
 * - Settings for behaviour (sound, rate, etc.).
 * - Play notification sound when room is unfocused.
 */
@Injectable({ providedIn: 'root' })
export class UnreadService {
  /** Renderer created from factory. */
  private readonly renderer: Renderer2 = this.rendererFactory.createRenderer(null, null);

  /** Whether this service is active. */
  private isActive = false;

  /** Observables to unsubscribe from later. */
  private subscriptions = new Subscription();

  /** Listener disposal method for window focus. */
  private windowFocusDisposal?: () => void;

  /** Favicon HTML element. */
  private readonly faviconElement: HTMLLinkElement | null = this.document.querySelector('#favicon');

  /** List of message IDs that are unread. */
  public readonly unreadMessages = new BehaviorSubject<UnreadMessage[]>([]);

  /** Triggers when app is focused again. */
  public readonly onFocus = new EventEmitter<void>();

  /** Whether unread messages are loaded. */
  public readonly unreadMessagesLoaded = new BehaviorSubject<boolean>(false);

  /** @returns Window instance using injected document. */
  private get window(): Window {
    return this.document.defaultView as Window;
  }

  /** Change the unread status of the app favicon. */
  private set unreadFavicon(status: boolean) {
    if (this.faviconElement) {
      if (status) {
        this.faviconElement.href = '/assets/favicon-unread.png';
      } else {
        this.faviconElement.href = '/assets/favicon.png';
      }
    }
  }

  /** @returns Whether app is currently focused. */
  public get hasFocus(): boolean {
    return this.document.hasFocus();
  }

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(API_SERVICE) private readonly apiService: ApiService,
    private readonly tenantService: TenantService,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
    private readonly rendererFactory: RendererFactory2,
    private readonly pusherService: PusherService,
  ) {}

  /**
   * Handle incoming message.
   *
   * - Unread status
   * - Desktop notification
   * - Room unread status
   *
   * @param message Message data.
   */
  private handleMessageCreate(message: MessagePusherApi): void {
    /** Don't do anything if new message is from authenticated member. */
    if (this.tenantService.authenticatedMember.id === message.member) {
      return;
    }
    /** Is focusing on the app and the message room? */
    const focused: boolean = this.hasFocus && this.tenantService.room.value === message.room;
    /** Get the room instance. */
    const room: Room = this.tenantService.getRoomById(message.room);
    /** Handle desktop notification. */
    if (!focused && this.notificationService.isGranted) {
      const member: Member = this.tenantService.getMemberById(message.member);
      let title = `${member.name} ${room.displayName}`;
      if (room.kind === RoomKind.DIRECT) {
        title = member.name;
      }
      this.notificationService.show(title, { body: stripTags(message.content) }, (): void => {
        this.markMessagesAsRead([message.id]);
        this.router.navigate(this.tenantService.getPath([room.id]).path);
      });
    }
    /** Handle message unread status. */
    this.markMessagesAsUnread([{ id: message.id, parent: message.parent, room: message.room }]);
  }

  /** Activate effects of this service. */
  public activate(): void {
    // Deactivate if is active.
    if (this.isActive) {
      this.deactivate();
    }
    // Set as active.
    this.isActive = true;
    /** Watch pusher message create event. */
    this.subscriptions.add(
      this.pusherService.events[PusherEvent.MESSAGE_CREATE].subscribe({
        next: (data: MessagePusherApi): void => {
          this.handleMessageCreate(data);
        },
      }),
    );
    /** Watch unread messages list and update unread status. */
    this.subscriptions.add(
      this.unreadMessages.subscribe({
        next: (unreadMessages: UnreadMessage[]): void => {
          /** Update unread favicon status. False if there are no unread messages. */
          this.unreadFavicon = unreadMessages.length !== 0;
          /** Update rooms unread status (only if changed). */
          for (const room of this.tenantService.rooms.value || []) {
            const unread: boolean = unreadMessages.some((item: UnreadMessage): boolean => item.room === room.id);
            if (unread !== room.unread.value) {
              room.unread.next(unread);
            }
          }
        },
      }),
    );
    /** When focusing on app, trigger on focus event. */
    this.windowFocusDisposal = this.renderer.listen(this.window, 'focus', (): void => {
      this.onFocus.emit();
    });
    /** Load unread messages initially. */
    this.loadUnreadMessages();
  }

  /** Deactivate effects of this service. */
  public deactivate(): void {
    this.isActive = false;
    this.windowFocusDisposal?.();
    this.subscriptions.unsubscribe();
    this.subscriptions = new Subscription();
    this.unreadMessages.next([]);
    this.unreadMessagesLoaded.next(false);
    this.unreadFavicon = false;
  }

  /** Load unread messages and apply unread status. */
  public loadUnreadMessages(): void {
    this.subscriptions.add(
      this.apiService.message.flex(['id', 'room', 'parent'], { limit: 500, is_unread: true }).subscribe({
        next: (data: ApiResponse<MessageApi>): void => {
          console.debug(`[UnreadService] Loaded ${data.results.length} unread messages`);
          this.markMessagesAsUnread(data.results);
          this.unreadMessagesLoaded.next(true);
        },
        error: (error: HttpErrorResponse): void => {
          const text = '[UnreadService] Failed to load unread messages.';
          console.warn(text, error.message, error.status);
          captureMessage(text);
        },
      }),
    );
  }

  /**
   * Add the given list of unread messages to the
   * existing list without duplicates.
   *
   * @param newUnreadMessages List of unread messages
   * to add.
   */
  public markMessagesAsUnread(newUnreadMessages: UnreadMessage[]): void {
    const unreadMessages: UnreadMessage[] = this.unreadMessages.getValue();
    // Create a new list by merging the current and new unread messages, ensuring no duplicates
    const mergedUnreadMessages = [
      ...unreadMessages,
      ...newUnreadMessages.filter((message: UnreadMessage): boolean => !findItemInList(unreadMessages, message.id)),
    ];
    // Only trigger .next() if something has been added
    if (mergedUnreadMessages.length !== unreadMessages.length) {
      this.unreadMessages.next(mergedUnreadMessages);
    }
  }

  /**
   * Remove the given unread messages from the list of unread messages.
   * Only triggers .next() if there are changes (messages removed).
   *
   * @param ids Message IDs of the data to remove.
   * @param noApiCall Whether to make prevent API call for marking.
   */
  public markMessagesAsRead(ids: UnreadMessage['id'][], noApiCall = false): void {
    const currentUnreadMessages: UnreadMessage[] = this.unreadMessages.getValue();
    // Find the messages that will actually be removed
    const removedMessages = currentUnreadMessages.filter((message: UnreadMessage): boolean => ids.includes(message.id));
    // Filter out messages that have the given IDs
    const updatedUnreadMessages = currentUnreadMessages.filter(
      (message: UnreadMessage): boolean => !ids.includes(message.id),
    );
    // Only trigger .next() if the list has changed (i.e., messages were removed)
    if (updatedUnreadMessages.length !== currentUnreadMessages.length) {
      this.unreadMessages.next(updatedUnreadMessages);
      // Make API call to mark message as read.
      if (!noApiCall) {
        const rooms: Record<UnreadMessage['room'], UnreadMessage['id'][]> = {};
        // Create a dict with room as ID and message IDs belonging to it as value.
        for (const removedMessage of removedMessages) {
          if (!rooms[removedMessage.room]) {
            rooms[removedMessage.room] = [];
          }
          rooms[removedMessage.room].push(removedMessage.id);
        }
        // Make a bulk API call for each room
        for (const [roomId, messageIds] of Object.entries(rooms)) {
          this.apiService.roomMarkBulkAsRead.create({ messages: messageIds }, roomId).subscribe();
        }
      }
    }
  }

  /** Marks all messages from a room. */
  public markRoomAsRead(id: UnreadMessage['room'], noApiCall = false): void {
    const unreadMessages: UnreadMessage['id'][] = this.unreadMessages.value
      .filter((item: UnreadMessage): boolean => item.room === id)
      .map((item: UnreadMessage): UnreadMessage['id'] => item.id);
    this.markMessagesAsRead(unreadMessages, noApiCall);
  }
}
