import { DetailBaseService } from '@amirsavand/ngx-common';
import { EventEmitter, Inject, Injectable, Injector } from '@angular/core';
import { StorageGroup } from '@app/shared/classes';
import { PusherEvent } from '@app/shared/enums/pusher-event';
import { ApiService } from '@app/shared/services/api.service';
import { PusherService } from '@app/shared/services/pusher.service';
import {
  Message,
  MessageApi,
  MessagePusherApi,
  MessagePusherData,
  MessageReactionPusherApi,
  MessageReactionPusherData,
  PusherEventOperation,
  Room,
  RoomMemberPusherData,
  TenantService,
} from '@app/tenant';
import { API_SERVICE } from '@SavandBros/savandbros-ngx-common';
import { Observable, Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RoomService extends DetailBaseService<Room> {
  private subscriptions = new Subscription();

  /** Triggers when this room is focused. */
  public readonly focus = new EventEmitter<void>();

  /** Triggers when a pusher event comes from message. */
  public readonly message = new EventEmitter<MessagePusherData>();

  /** Triggers when a pusher event comes from message reaction. */
  public readonly messageReaction = new EventEmitter<MessageReactionPusherData>();

  /** @returns storage group instance for messages of this room. */
  public get messagesStorageGroup(): StorageGroup<MessageApi> {
    return StorageGroup.getOrCreate<MessageApi>({
      database: 'room-message',
      table: `${this.pk.value}`,
      limitation: 100,
    });
  }

  /**
   * Triggers when a pusher event comes from room
   * member (triggers from tenant service).
   *
   * Emitted when room member belongs to the current
   * room.
   */
  public readonly roomMember = new EventEmitter<RoomMemberPusherData>();

  constructor(
    @Inject(API_SERVICE) private readonly apiService: ApiService,
    private readonly pusherService: PusherService,
    private readonly tenantService: TenantService,
    private readonly injector: Injector,
  ) {
    super();
  }

  /**
   * Handler (callback) for all message events.
   *
   * @param data Event data.
   * @param operation Event operation.
   */
  private messageEventHandler(data: MessagePusherApi, operation: PusherEventOperation): void {
    /** Ignore messages from other rooms. */
    if (this.pk.value !== data.room) {
      return;
    }
    /** Check if room data is set. */
    if (this.data.value) {
      /** Set up the emit data. */
      const emitData: MessagePusherData = { data, operation };
      /** Message class only available for non-destroy operations. */
      if (operation !== PusherEventOperation.DESTROY) {
        emitData.message = new Message(data, this.data.value, this.injector);
      }
      /** Emit to message. */
      this.message.emit(emitData);
    }
  }

  /**
   * Handler (callback) for all message reaction events.
   *
   * @param data Event data.
   * @param operation Event operation.
   */
  private messageReactionEventHandler(data: MessageReactionPusherApi, operation: PusherEventOperation): void {
    /** Ignore message reactions from other rooms. */
    if (this.pk.value !== data.room) {
      return;
    }
    /** Find the member. */
    const member = this.tenantService.getMemberById(data.reaction.member);
    /** Emit to message reaction. */
    this.messageReaction.emit({ data, operation, member });
  }

  /**
   * Add reaction to a message.
   * @param message Message for reaction.
   * @param reaction Code for reaction.
   * @returns API observable.
   */
  public reactToMessage(message: Message, reaction: string): Observable<{ reaction_code: string }> {
    return this.apiService.messageReact.create({ reaction_code: reaction }, message.id);
  }

  /** Activate this service to listen to events. */
  public activate(): void {
    /** Watch event to emit message. */
    this.subscriptions.add(
      this.pusherService.events[PusherEvent.MESSAGE_CREATE].subscribe({
        next: (data: MessagePusherApi): void => {
          this.messageEventHandler(data, PusherEventOperation.CREATE);
        },
      }),
    );
    /** Watch event to emit message. */
    this.subscriptions.add(
      this.pusherService.events[PusherEvent.MESSAGE_UPDATE].subscribe({
        next: (data: MessagePusherApi): void => {
          this.messageEventHandler(data, PusherEventOperation.UPDATE);
        },
      }),
    );
    /** Watch event to emit message. */
    this.subscriptions.add(
      this.pusherService.events[PusherEvent.MESSAGE_DESTROY].subscribe({
        next: (data: MessagePusherApi): void => {
          this.messageEventHandler(data, PusherEventOperation.DESTROY);
        },
      }),
    );
    /** Watch event to emit message reaction. */
    this.subscriptions.add(
      this.pusherService.events[PusherEvent.MESSAGE_REACTION_CREATE].subscribe({
        next: (data: MessageReactionPusherApi): void => {
          this.messageReactionEventHandler(data, PusherEventOperation.CREATE);
        },
      }),
    );
    /** Watch event to emit message reaction. */
    this.subscriptions.add(
      this.pusherService.events[PusherEvent.MESSAGE_REACTION_DESTROY].subscribe({
        next: (data: MessageReactionPusherApi): void => {
          this.messageReactionEventHandler(data, PusherEventOperation.DESTROY);
        },
      }),
    );
    /** Watch event to emit room member. */
    this.subscriptions.add(
      this.tenantService.roomMemberPusher.subscribe({
        next: (data: RoomMemberPusherData): void => {
          /** Only emit when the room member belongs to current room. */
          if (data.data.room === this.pk.value) {
            this.roomMember.emit(data);
          }
        },
      }),
    );
  }

  /** Deactivate effects of this service. */
  public deactivate(): void {
    this.subscriptions.unsubscribe();
    this.subscriptions = new Subscription();
  }

  override clear(): void {
    super.clear();
    this.deactivate();
  }
}
