import { HttpErrorResponse, removeChild } from '@amirsavand/ngx-common';
import { Injector } from '@angular/core';
import { AppService } from '@app/app.service';
import { Member, Message, MessageReactionPusherData, TenantService } from '@app/tenant';
import { RoomService } from '@app/tenant/room';
import { Emoji } from '@ctrl/ngx-emoji-mart/ngx-emoji';

export interface MessageReactionData {
  message: Message;
  reaction: string;
  members: Member[];
}

export class MessageReaction {
  private readonly tenantService: TenantService = this.injector.get(TenantService);

  private readonly message: Message = this.init.message;

  public readonly reaction: string = this.init.reaction;

  public readonly emoji = { unified: this.reaction } as Emoji['emoji'];

  public readonly members: Member[] = this.init.members;

  public tooltip = '';

  public fromAuthenticatedMember = false;

  public loadingReact = false;

  constructor(
    private readonly init: MessageReactionData,
    private readonly injector: Injector,
  ) {
    this.generateFromAuthenticatedMember();
    this.generateTooltip();
  }

  private generateTooltip(): void {
    this.tooltip = this.members.map((member: Member): string => member.name).join('\n');
  }

  public generateFromAuthenticatedMember(): void {
    this.fromAuthenticatedMember = this.members.includes(this.tenantService.authenticatedMember);
  }

  public react(): void {
    const roomService: RoomService = this.injector.get(RoomService);
    const appService: AppService = this.injector.get(AppService);
    this.loadingReact = true;
    roomService.reactToMessage(this.message, this.reaction).subscribe({
      next: (): void => {
        this.loadingReact = false;
      },
      error: (error: HttpErrorResponse): void => {
        this.loadingReact = false;
        appService.alertErrorApi(error, 'Failed to react to message.');
      },
    });
  }

  /**
   * Handles reaction events.
   *
   * Must be triggered when a message reaction event
   * comes from pusher.
   *
   * @param data Message reaction pusher data.
   */
  public onChange(data: MessageReactionPusherData): void {
    if (data.data.reaction.is_added) {
      this.members.push(data.member);
    } else {
      removeChild(this.members, data.member);
      if (!this.members.length) {
        removeChild(this.message.reactions, this);
        return;
      }
    }
    this.generateFromAuthenticatedMember();
    this.generateTooltip();
  }
}
