import { HttpErrorResponse, Payload } from '@amirsavand/ngx-common';
import { Injector } from '@angular/core';
import { AppService } from '@app/app.service';
import { ApiService } from '@app/shared/services/api.service';
import { Member, RoomMemberApi, RoomMemberFullApi, RoomMemberRole, TenantService } from '@app/tenant';
import { API_SERVICE } from '@SavandBros/savandbros-ngx-common';

export interface RoomMemberInit extends RoomMemberApi {
  room: number;
}

export class RoomMember {
  private readonly tenantService: TenantService = this.injector.get(TenantService);

  private readonly apiService = this.injector.get(API_SERVICE) as ApiService;

  private readonly appService: AppService = this.injector.get(AppService);

  public readonly id: number = this.init.id;

  public readonly member: Member = this.tenantService.getMemberById(this.init.member);

  public readonly role: RoomMemberRole = this.init.role;

  public loadingKick = false;

  public loadingUpdate = false;

  constructor(
    private readonly init: RoomMemberInit,
    private readonly injector: Injector,
  ) {}

  /**
   * Remove room member from the room they are in.
   * @summary Destroy room member object.
   */
  public remove(): void {
    this.loadingKick = true;
    this.apiService.roomMember.destroy(this.id, this.init.room).subscribe({
      next: (): void => {
        this.loadingKick = false;
        this.appService.alertSuccess(`Removed ${this.member.name} from room.`);
      },
      error: (error: HttpErrorResponse): void => {
        this.loadingKick = false;
        this.appService.alertErrorApi(error, `Failed to remove ${this.member.name} from room.`);
      },
    });
  }

  /** Update room member via API. */
  public update(payload: Payload<RoomMemberFullApi>): void {
    this.loadingUpdate = true;
    this.apiService.roomMember.update(this.id, payload, this.init.room).subscribe({
      next: (): void => {
        this.loadingUpdate = false;
        this.appService.alertSuccess(`Updated ${this.member.name} successfully.`);
      },
      error: (error: HttpErrorResponse): void => {
        this.loadingUpdate = false;
        this.appService.alertErrorApi(error, `Failed to update ${this.member.name}.`);
      },
    });
  }
}
