import { Dict, PK } from '@amirsavand/ngx-common';
import { captureMessage } from '@sentry/angular-ivy';
import localforage from 'localforage';

/** Initiation data structure of a storage group instance. */
export interface StorageGroupInit<T extends StorageGroupItem> {
  /** Database name. */
  database: StorageGroup<T>['database'];
  /** Table name. */
  table: StorageGroup<T>['table'];
  /** Limitation (optional). */
  limitation?: StorageGroup<T>['limitation'];
}

/** Base item data structure of a storage group item. */
export interface StorageGroupItem {
  id: PK;
}

/** Storage Group represents a table in a database. */
export class StorageGroup<T extends StorageGroupItem> {
  /** All instances of storage group mapped to their database and table names. */
  private static instance: Dict<StorageGroup<any>> = {};

  /** LocalForge instance configuration of this storage group. */
  public readonly instance: LocalForage;

  /** Name of the database of this storage group. */
  public readonly database: string;

  /** name of the table of this storage group. */
  public readonly table: string;

  /** Maximum number of items to store for this storage group. */
  public readonly limitation: number | null = null;

  /** Get or create an instance of a storage group. */
  public static getOrCreate<T extends StorageGroupItem>(init: StorageGroupInit<T>): StorageGroup<T> {
    const key = `${init.database}:${init.table}`;
    if (key in StorageGroup.instance) {
      return StorageGroup.instance[key];
    }
    return new StorageGroup<T>(init);
  }

  /**
   * Attempt to get instance with the given database and table.
   * @returns `null` if not created yet.
   * @returns `StorageGroup` instance if exists.
   */
  public static get<T extends StorageGroupItem>(
    init: Pick<StorageGroupInit<T>, 'database' | 'table'>,
  ): StorageGroup<T> | null {
    const key = `${init.database}:${init.table}`;
    if (key in StorageGroup.instance) {
      return StorageGroup.instance[key];
    }
    return null;
  }

  /** Logging with prefix defined. */
  private log(...args: any[]): void {
    console.debug(`[StorageGroup] ${this.database}:${this.table}`, ...args);
  }

  /** Logging with error with prefix defined.*/
  private error(...args: any[]): void {
    this.log(...args);
    captureMessage(args.join(' '));
  }

  private constructor(init: StorageGroupInit<T>) {
    // Save self to static instances map
    StorageGroup.instance[`${init.database}:${init.table}`] = this;
    // Store initial values
    this.database = init.database;
    this.table = init.table;
    if (init.limitation) {
      this.limitation = init.limitation;
    }
    // Setup database and table
    localforage.config({
      driver: localforage.INDEXEDDB,
      name: this.database,
    });
    this.instance = localforage.createInstance({
      name: this.database,
      storeName: this.table,
    });
    // Run limitation cleanup
    this.limitationCleanup();
  }

  /** Create or update the given item. */
  public async set(item: T): Promise<void> {
    try {
      await this.instance.setItem(String(item.id), item);
    } catch (error: unknown) {
      if (error !== 'QuotaExceededError') {
        this.error(`failed to set item ${item.id}`, error);
      }
    }
  }

  /** Destroy an item via the given ID. */
  public async destroy(id: T['id']): Promise<void> {
    try {
      await this.instance.removeItem(String(id));
    } catch (error: unknown) {
      this.error(`failed to destroy item ${id}`, error);
    }
  }

  /** Retrieve a single item via the given ID. */
  public async retrieve(id: T['id']): Promise<T | null> {
    try {
      const item: T | null = await this.instance.getItem<T>(String(id));
      if (!item) {
        return null;
      }
      return item;
    } catch (error: unknown) {
      this.error(`failed to retrieve item ${id}`, error);
    }
    return null;
  }

  /** @returns list of items. */
  public async list(sortById: boolean = true): Promise<T[]> {
    const items: T[] = [];
    try {
      await this.instance.iterate((item: T): void => {
        items.push(item);
      });
      if (sortById) {
        return items.sort((a: T, b: T): number => Number(b.id) - Number(a.id));
      }
      return items;
    } catch (error: unknown) {
      this.error('failed to list items', error);
    }
    return [];
  }

  /** Clear out all data in this table. */
  public async clear(): Promise<void> {
    try {
      await this.instance.clear();
    } catch (error: unknown) {
      this.error('failed to clear items', error);
    }
  }

  /** Remove old items until limitation is met. */
  public async limitationCleanup(): Promise<void> {
    if (!this.limitation) {
      return;
    }
    const max: number = this.limitation;
    const length: number = await this.instance.length();
    if (length <= max) {
      return;
    }
    const items: T[] = await this.list();
    items.forEach((item: T, index: number): void => {
      if (index >= max) {
        this.destroy(item.id);
      }
    });
  }
}
