import { Message } from '@app/tenant';
import { format, isThisYear, isToday, isYesterday } from 'date-fns';

export interface MessageGroupInit {
  date: string;
  showHeader?: boolean;
}

export class MessageGroup {
  /** Common date of all messages. */
  public readonly id: string;

  /** List of messages. */
  public readonly messages: Message[] = [];

  /**
   * Whether to date and divider.
   * Useful for places like a thread main
   * message.
   */
  public readonly showHeader: boolean = true;

  /** Label based on group date. */
  public readonly label?: string;

  constructor(init: MessageGroupInit) {
    this.id = MessageGroup.getGroupIdByMessageDate(init.date);
    if (init.showHeader !== undefined) {
      this.showHeader = init.showHeader;
    }
    /** Label generation. */
    if (this.showHeader) {
      if (isToday(init.date)) {
        this.label = 'Today';
      } else if (isYesterday(init.date)) {
        this.label = 'Yesterday';
      } else if (isThisYear(init.date)) {
        this.label = format(init.date, 'EEEE, MMMM dd');
      } else {
        this.label = format(init.date, 'yyyy MMMM dd');
      }
    }
  }

  /**
   * Returns date without a timezone which
   * represents ID of a group.
   *
   * @param date Date to modify.
   */
  public static getGroupIdByMessageDate(date: string): string {
    if (date === 'header') {
      return date;
    }
    return format(date, 'yyyy-MM-dd');
  }
}
