import { NotificationBaseService } from '@amirsavand/ngx-common';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

/** @see NotificationBaseService */
@Injectable({ providedIn: 'root' })
export class NotificationService extends NotificationBaseService {
  constructor(@Inject(DOCUMENT) document: Document) {
    super(document.defaultView as Window);
  }
}
