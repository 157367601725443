import { finalize, Observable, of } from 'rxjs';

/**
 * The `IsTyping` class manages the state of a broadcasting
 * process (e.g., typing indicator in a chat) and
 * ensures broadcasts are rate-limited.
 *
 * @template T - The type of data emitted by the observable
 * function.
 */
export class IsTyping<T> {
  /** A flag indicating whether a broadcast is currently active. */
  private isBroadcasting = false;

  /**
   * Timestamp of the last broadcast, used to calculate
   * the time interval since the last broadcast.
   */
  private lastBroadcastTime = 0;

  /**
   * @param rate The minimum interval (in milliseconds)
   * between consecutive broadcasts.
   *
   * @param getObservable A function that returns
   * an observable which emits the broadcast data.
   */
  constructor(
    private readonly rate = 500,
    private readonly getObservable: () => Observable<T>,
  ) {}

  /**
   * Attempts to initiate a broadcast if the rate
   * limit allows.
   *
   * - If a broadcast is already active (`isBroadcasting`
   *   is true) or the time since the last broadcast
   *   is less than the specified rate, this method
   *   will return an empty observable.
   * - If the rate limit allows a broadcast, it activates
   *   broadcasting, retrieves the observable from
   *   `getObservable`, and resets the `isBroadcasting`
   *   flag and updates `lastBroadcastTime` when the
   *   observable completes.
   *
   * @returns The observable returned by `getObservable`
   * if broadcasting is permitted, otherwise an empty
   * observable.
   */
  public broadcast(): Observable<T> {
    // Rate-limited: Return an empty observable if
    // currently broadcasting or within rate interval
    if (this.isBroadcasting || Date.now() - this.lastBroadcastTime < this.rate) {
      return of();
    }
    // Mark broadcasting as active
    this.isBroadcasting = true;
    return this.getObservable().pipe(
      finalize((): void => {
        // Reset broadcasting flag
        this.isBroadcasting = false;
        // Update last broadcast time
        this.lastBroadcastTime = Date.now();
      }),
    );
  }
}
