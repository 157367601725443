import { SelectItem } from '@amirsavand/ngx-input';
import { Injector } from '@angular/core';
import { ApiService } from '@app/shared/services/api.service';
import { MemberPublic, MemberRole, RoomApi } from '@app/tenant';
import { API_SERVICE, extractUploadedFile, UploadedFile } from '@SavandBros/savandbros-ngx-common';
import { finalize, Observable, tap } from 'rxjs';

export class Member {
  private readonly apiService = this.injector.get(API_SERVICE) as ApiService;

  public readonly id: number = this.init.id;

  public name: string = this.init.display_name;

  public isActive: boolean = this.init.is_active;

  public isOnline = false;

  public picture: UploadedFile | null = this.init.picture;

  public role: MemberRole = this.init.role;

  public timezone: string = this.init.timezone;

  public hasDirect = false;

  public loadingCreateDirect = false;

  /** Select item data for mentions. */
  public get selectItem(): SelectItem {
    return {
      icon: extractUploadedFile(this, 'picture', '/assets/member.svg'),
      name: this.name,
      id: this.id,
    };
  }

  constructor(
    public readonly init: MemberPublic,
    private readonly injector: Injector,
  ) {}

  /**
   * Create direct room for this member and authenticated
   * member.
   */
  public createDirect(): Observable<RoomApi> {
    this.loadingCreateDirect = true;
    return this.apiService.roomCreateDirect.create({ participant: this.id }).pipe(
      finalize((): void => {
        this.loadingCreateDirect = false;
      }),
      tap((): void => {
        this.hasDirect = true;
      }),
    );
  }

  /**
   * Must be called when data of this member is updated.
   * Regenerate specific data values.
   *
   * @param data of pusher event data.
   */
  public onChange(data: MemberPublic): void {
    console.debug(`[Member] ${this.name} (${this.id}) was updated via pusher event.`);
    Object.assign(this.init, data);
    this.name = data.display_name;
    this.isActive = data.is_active;
    this.picture = data.picture;
    this.role = data.role;
    this.timezone = data.timezone;
  }
}
