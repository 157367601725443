import { Link as LinkBase, LinkData as LinkDataBase } from '@amirsavand/ngx-common';
import { BootstrapColor } from '@app/shared/types/bootstrap-color';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { BehaviorSubject } from 'rxjs';

/** Initial data for {@see Link}. */
export interface LinkData extends LinkDataBase {
  id?: Link['id'];
  icon?: Link['icon'];
  image?: Link['image'];
  indicator?: Link['indicator'];
  unread?: Link['unread'];
  typing?: Link['typing'];
}

/** Single link used for UI (can be used via {@see LinkGroup}). */
export class Link extends LinkBase {
  /** Unique ID of this link. */
  public id?: number = this.init.id;

  /** UI icon of this link. */
  public icon?: IconDefinition = this.init.icon;

  /** UI image of this link. */
  public image?: string = this.init.image;

  /** UI indicator of this link. */
  public indicator?: BootstrapColor = this.init.indicator;

  /** UI unread status of this link. */
  public unread?: BehaviorSubject<boolean> = this.init.unread;

  /** UI typing status of this link. */
  public typing?: BehaviorSubject<boolean> = this.init.typing;

  constructor(private readonly init: LinkData) {
    super(init);
  }
}
